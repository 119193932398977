<template>
  <div class="container--is-maxwidth container--padded faq">
    <div class="faq_accordions__wrapper">
        <div class="accordion__list" v-for="(list, index) in faqData" :key="index">
            <div class="accordion" v-html="list.Question"/>
            <span class="faq-accordion-btn" :class="{'is-open': list.isDefault}"  @click="toggle(index,list.Question)"/>
            <div class="accordion-content" v-html="removeHtmlAndSpecialChars(list.Answer)"></div>
        </div>
    </div>
  </div>
</template>

<script>
import { AnalyticsHandler } from '@Foundation/analyticsHandler';
export default {
    name:'faq',
    props:{
        fieldNewgdpr: {
            type: Array,
            default() {
            return undefined;
            }
        },
        pageCategory:{ type: String, required: false, default: '' },
    },
    data() {
        return {
            faqData : []
        }
    },
    created() {
        const data = this.fieldNewgdpr;
        data.forEach(element => { 
            const list = {};
            list.Question = element.Question;
            list.Answer = element.Answer;
            list.isDefault = false;
            this.faqData.push(list);
        });
    },
    methods: {
        toggle(index,accordianTitle) {
            this.faqData[index].isDefault = !this.faqData[index].isDefault;
            let accTitle =accordianTitle.replace("(+)","plus").toLowerCase();
            AnalyticsHandler.pushDataLayer({
                'event': 'uaevent',
                'ecommerce':'undefined',
                'event_name': 'body_button_click',
                'eventCategory': this.pageCategory,
                'eventAction': 'select::faq',
                'eventLabel': accTitle+'::none',
                'module_name': 'faq',
                'cta_name': accTitle,
                'link_url': 'none'
            });
        },
        removeHtmlAndSpecialChars(inputString) {
        // Create a DOMParser to parse the inputString as HTML
        const parser = new DOMParser();
        const doc = parser.parseFromString(inputString, 'text/html');
        const plainText = doc.body.innerHTML || "";

        return plainText.replaceAll('\n', '');
      },
    }

}
</script>
<style lang="scss" src="./faq.scss"></style>